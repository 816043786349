import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { usePermissions } from '@/composables/usePermissions';

const { can, canAny } = usePermissions();

export async function permissionGuard(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  const permission = to.meta?.permission;

  if (permission === null || permission === undefined || (Array.isArray(permission) && permission.length === 0)) {
    return next();
  }

  const permissionToCheck = permission ?? ''; // Default to an empty string if permission is undefined

  if (Array.isArray(permissionToCheck)) {
    if (canAny(permissionToCheck)) {
      return next();
    }
  }
  else if (can(permissionToCheck)) {
    return next();
  }

  return next({ path: 'unauthorized' });
}
