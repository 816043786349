<template>
  <model-edition-form
    ref="commentForm"
    v-model="comment"
    :model-name="t('task.comment')"
    base-url="/api/taskcomments"
    :model-id="props.modelId"
    :mode="props.mode"
    :with-title="false"
    save-wording="Comment"
    :can-close="false"
    @close="emit('close')"
    @model:updated="emit('model:updated')"
    @model:created="emit('model:created')"
  >
    <div v-if="comment">
      <rich-text-editor
        v-model="comment.comment"
        :hint="t('task.comment_hint')"
        :error="!!validationErrors.comment"
        :error-messages="validationErrors.comment"
      />
      <v-select
        v-if="advanceStates"
        v-model="statusCode"
        class="mt-2"
        :label="t('global.status')"
        :items="advanceStates"
      />
    </div>

    <template #actions>
      <v-btn
        color="primary"
        :text="t('task.comment_and_advance')"
        @click="addCommentAndAdvance"
      />
    </template>
  </model-edition-form>
</template>

<script setup lang="ts">
import type { AxiosError } from 'axios';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ModelEditionForm from '../ModelEditionForm.vue';
import RichTextEditor from '../RichTextEditor.vue';

const props = defineProps<{
  modelId?: number;
  mode: 'edit' | 'create' | 'show';
  task: App.Models.Task;
}>();
const emit = defineEmits(['model:updated', 'model:created', 'model:loaded', 'error:handled', 'close']);
const errorStore = useErrorStore();
const { validationErrors } = storeToRefs(errorStore);
const { t } = useI18n();
const { showSnackMessage } = useErrorStore();
const commentForm = ref<typeof ModelEditionForm>();

const statusCode = ref<string>('');
const comment = ref<Partial<App.Models.TaskComment>>();

if (!comment.value) {
  comment.value = {
    id: -1,
    task_id: props.task.id,
    comment: '',
  };
}
const taskStates = ref<any>(null);

const advanceStates = computed(() => taskStates.value?.filter((state: any) => state.value !== 'new' && state.value !== props.task.status));
async function loadTaskStates() {
  const response = await axios.get('/api/tasks/states');
  taskStates.value = response.data.data.status.map((status: string) => ({
    value: status,
    title: t(`states.${status}`),
  }));
}

async function addCommentAndAdvance() {
  try {
    commentForm.value?.save();
    const newTask = props.task;
    newTask.status = statusCode.value;
    await axios.put(`/api/tasks/${props.task.id}`, newTask);
    if (comment.value) {
      comment.value.comment = '';
      statusCode.value = '';
    }
    emit('model:updated');
  }
  catch (error) {
    const errors = error as Error | AxiosError;
    if (axios.isAxiosError(errors)) {
      if (errors.response?.status === 422) {
        validationErrors.value = errors.response.data.errors;
      }
    }
    showSnackMessage(t('task.cannot_update'));
  }
}

if (!taskStates.value) {
  loadTaskStates();
}

watch(comment, () => {
  commentForm.value?.validate();
}, { deep: true });
</script>
