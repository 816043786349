<template>
  <v-card
    class="text-center fill-height d-flex flex-column"
    rounded="lg"
    data-testid="course-session-invite-widget"
  >
    <v-card-title
      class="bg-primary-lighten-1 pt-7"
      style="min-height: 200px"
    >
      <div class="text-h4 text-capitalize text-primary-darken-1 mb-7 text-wrap">
        {{ t('course.training_invitation') }}
      </div>
      <h1>
        <v-icon
          size="large"
          icon="fas fa-chalkboard-teacher"
          color="primary"
        />
      </h1>
    </v-card-title>
    <v-card-title class="text-primary-darken-1 text-h6">
      {{ courseSession?.course?.name }}
    </v-card-title>
    <v-card-text>
      <p class="text-body-1">
        {{ toLongDateWithDay(courseSession.date) }}<br>{{ courseSessionCaption }}
      </p>
    </v-card-text>
    <v-card-text v-if="action">
      <v-icon
        size="large"
        icon="fas fa-check-circle"
        color="success"
      />
      {{ t('course.invitation_action_message', { action: action }) }}
    </v-card-text>
    <v-card-actions v-else>
      <v-row>
        <v-col>
          <v-btn
            :to="`/training/${courseSession.id}`"
            :text="t('global.view_details')"
            color="primary"
            size="large"
            block
          />
        </v-col>
        <v-col v-if="courseSession?.pivot?.status === 'pending' && !action">
          <v-btn
            color="success"
            :text="t('actions.accept')"
            size="large"
            block
            @click="acceptInvitation"
          />
        </v-col>
        <v-dialog
          v-model="dialog"
          max-width="400px"
        >
          <template #activator="{ props: activatorProps }">
            <v-col>
              <v-btn
                v-bind="activatorProps"
                color="error"
                :text="t('actions.decline')"
                block
                size="large"
              />
            </v-col>
          </template>
          <global-card :title="t('course.decline_invitation')">
            {{ t('course.decline_invitation_confirmation') }}
            <template #actions>
              <v-btn
                :text="t('actions.close')"
                color="dark-grey"
                @click="close"
              />
              <v-btn
                v-if="!action"
                color="error"
                :text="t('course.decline_invitation')"
                @click="declineInvitation"
              />
            </template>
          </global-card>
        </v-dialog>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  courseSession: App.DataTables.NextCourseSession;
}>();

const emit = defineEmits(['reload']);

const { t } = useI18n();

const { toLongDateWithDay, toShortTime } = useDate();
const { handleError, showSnackMessage } = useErrorStore();

const action = ref<string>('');
const dialog = ref(false);

const courseSessionCaption = computed(() => {
  const begins = toShortTime(props.courseSession.begins);
  const ends = toShortTime(props.courseSession.ends);
  return `${begins} - ${ends}`;
});

function close() {
  dialog.value = false;
}

async function acceptInvitation() {
  const url = `/api/coursesessions/${props.courseSession.id}/accept`;
  try {
    await axios.put(url);
    action.value = t('states.accepted');
    setTimeout(() => {
      action.value = '';
      emit('reload');
    }, 3000);
  }
  catch (error) {
    handleError(error, 'Could not accept invitation');
  }
}
async function declineInvitation() {
  const url = `/api/coursesessions/${props.courseSession.id}/decline`;
  try {
    await axios.put(url);
    action.value = t('course.declined');
    showSnackMessage(t('course.decline_invitation_message'));
    emit('reload');
  }
  catch (error) {
    handleError(error, t('course.decline_invitation_error'));
  }
}
</script>
