<template>
  <model-select-dropdown
    v-model="model"
    :label="label"
    :per-page="perPage"
    :index-route="baseRoute"
    :name="name"
    :label-field-name="labelFieldName"
    :id-field-name="idFieldName"
    :search-fields="searchFields"
    :fields="fields"
    :sort-field-name="sortFieldName"
    :disabled="disabled"
    :error="error"
    :error-messages="errorMessages"
    :state="state"
    :clear-on-select="clearOnSelect"
    :index-route-parameters="indexRouteParameters"
    :includes="includes"
    :filters="activeFilters"
    :hint="hint"
    :persistent-hint="persistentHint"
    :return-object="returnObject"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ModelSelectDropdown from '../crud/ModelSelectDropdown.vue';

const props = withDefaults(defineProps<{
  label: string;
  perPage?: number;
  fields?: Array<string>;
  name?: string;
  labelFieldName?: string;
  idFieldName?: string;
  searchFields?: Array<string>;
  sortFieldName?: string;
  disabled?: boolean;
  state?: boolean;
  clearOnSelect?: boolean;
  includes?: Array<string>;
  excludeUserId?: number;
  activeOnly?: boolean;
  genderFilter?: string;
  requiredPermission?: string;
  error?: boolean;
  errorMessages?: Array<string>;
  type?: string;
  hint?: string;
  persistentHint?: boolean;
  returnObject?: boolean;
  basic?: boolean;
}>(), {
  labelFieldName: () => 'name',
  idFieldName: () => 'id',
  searchFields: () => ['first_name', 'last_name'],
  disabled: () => false,
  clearOnSelect: () => false,
  includes: () => [],
  activeOnly: () => false,
  error: () => false,
  errorMessages: () => [],
  perPage: () => 1000,
  fields: () => [],
  persistentHint: () => true,
  returnObject: () => false,
  basic: () => true,
});

const model = defineModel<App.Models.User | number>();

const baseRoute = '/api/users';

const indexRouteParameters = computed(() => ({ required_permission: (props.requiredPermission) ? props.requiredPermission : '', basic: props.basic }));
const activeFilters = computed(() => {
  const filters: Array<SearchFilter> = [];
  if (props.type) {
    filters.push({
      column: 'type',
      operator: '=',
      value: props.type,
    });
  }
  if (props.activeOnly) {
    filters.push({
      column: 'active',
      operator: '=',
      value: 1,
    });
  }
  if (props.genderFilter) {
    filters.push({
      column: 'gender',
      operator: '=',
      value: props.genderFilter,
    });
  }
  if (props.excludeUserId) {
    filters.push({
      column: 'id',
      operator: '!=',
      value: props.excludeUserId,
    });
  }
  return filters;
});
</script>
