import type { AxiosError } from 'axios';
import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useErrorStore = defineStore('error', () => {
  const validationErrors = ref<ValidationError>({});
  const validationMessage = ref('');
  const snackMessage = ref('');
  const snackVisible = ref(false);
  const snackType = ref('error');

  function handleValidationError(error: any): void {
    validationErrors.value = {};
    validationMessage.value = '';
    const errors = error as Error | AxiosError;
    if (axios.isAxiosError(errors)) {
      if (errors.response?.status === 422) {
        validationErrors.value = errors.response.data.errors;
      }
      validationMessage.value = errors?.response?.data?.message ?? '';
    }
  }

  function showSnackMessage(message: string, type: VAlertType = 'error', quiet: boolean = false): void {
    snackMessage.value = message;
    snackType.value = type;
    if (quiet) {
      return;
    }
    snackVisible.value = true;
  }

  function handleError(err: any, fallback: string = 'An error occurred', quiet: boolean = false): void {
    const error = err as Error | AxiosError;
    if (!axios.isAxiosError(error)) {
      console.error(error);
      showSnackMessage(fallback, 'error', quiet);
      return;
    }
    if (error.response?.status === 422) {
      showSnackMessage(error.response.data.message, 'error', quiet);
    }
    showSnackMessage(error?.response?.data?.message ?? fallback, 'error', quiet);
  }

  return {
    validationErrors,
    validationMessage,
    snackMessage,
    snackType,
    snackVisible,
    showSnackMessage,
    handleValidationError,
    handleError,
  };
});
