<template>
  <v-tab
    v-bind="props"
    :value="value"
    variant="flat"
    class="me-1 tab-unselected rounded-t-lg border-t-sm border-s-sm border-e-sm"
    selected-class="tab-selected"
  >
    <slot>{{ text }}</slot>
  </v-tab>
</template>

<script setup lang="ts">
const props = defineProps<{
  text?: string;
  value: string | number;
  active: boolean;
}>();
</script>

<style>
.tab-unselected {
  color: rgb(var(--v-theme-primary-darken-1)) !important;
  background-color: rgb(var(--v-theme-primary-lighten-1)) !important;
}
  .tab-selected {
    background-color: rgb(var(--v-theme-primary)) !important;
    color: white !important;
  }

  /* .tab-unselected:hover { */
    /* background-color: rgb(var(--v-theme-light-grey)); */
  /* } */
</style>
