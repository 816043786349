<template>
  <v-card
    class="fill-height text-center d-flex flex-column"
    rounded="lg"
    data-testid="shift-widget"
  >
    <v-img
      max-height="200px"
      :src="locationHeroImage"
      cover
    />
    <v-card-title class="text-center text-h6 text-primary-darken-1 mb-n2 text-wrap">
      {{ caption }}
    </v-card-title>
    <v-card-text>
      <p class="text-subtitle-1 text-center">
        {{ shift.location?.name }}
      </p>
      <div class="text-body-1 text-center mb-n2">
        {{ toLongDateWithDay(shift.date) }} | {{ toShortTime(shift.begins) }} - {{ toShortTime(shift.ends) }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        color="primary"
        size="large"
        :to="shiftDetailsUrl"
        :text="t('shift.view_details')"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  shift: App.Models.Shift;
  caption: string;
}>();

const { t } = useI18n();

const { toLongDateWithDay, toShortTime } = useDate();
const shiftDetailsUrl = computed(() => `/shifts/details/${props.shift.id}`);

const locationHeroImage = computed(() => props.shift?.location?.hero_image?.signed_file_url ?? '/storage/images/defaut_location_hero.jpg');
</script>
