<template>
  <v-tooltip :disabled="!icon">
    <template #activator="{ props: tooltipProps }">
      <v-menu>
        <template #activator="{ props: menuProps }">
          <v-btn
            v-if="icon"
            icon="fas fa-language"
            color="primary-darken-1"
            v-bind="{ ...menuProps, ...tooltipProps }"
          />
          <v-btn
            v-else
            variant="text"
            :text="t('language.title')"
            v-bind="{ ...menuProps, ...tooltipProps, ...$attrs }"
          />
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in availableLanguages"
            :key="index"
            :value="index"
            :active="item.locale === locale"
          >
            <v-list-item-title @click="setSelectedLanguage(item.locale)">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>{{ t('language.title') }}</span>
  </v-tooltip>
</template>

<script setup lang="ts">
import type { PermittedLocale } from '@/i18n';
import { useAuth } from '@/composables/useAuth';
import { availableLanguages } from '@/i18n';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { useI18n } from 'vue-i18n';

withDefaults(defineProps<{
  icon?: boolean;
}>(), {
  icon: () => false,
});

const emit = defineEmits<{ update: [string] }>();

const { showSnackMessage } = useErrorStore();

const { locale, t } = useI18n();
const { authUser } = useAuth();

async function setSelectedLanguage(selectedLocale: PermittedLocale) {
  if (authUser.id) {
    try {
      showSnackMessage(t('states.loading'), 'warning');
      await axios.patch('/api/users/me', { preferred_language: selectedLocale });
      // many parts of many components are memoized and since locale change affects so many components, it's easier to just reload the page
      // if someone wants to hunt down all the memoized parts and update them, feel free to do so, so that we can remove the reload
      window.location.reload();
    }
    catch (error) {
      console.error('Problem saving users preferred locale', error);
    }
  }
  emit('update', selectedLocale);
}
</script>
