<template>
  <v-card
    v-if="comment"
    class="mb-2"
  >
    <v-card-title class="text-font">
      <user-avatar
        v-if="comment.commenter"
        :user="comment.commenter"
        size="45"
      />
      {{ (comment.commenter) ? comment.commenter.full_name : 'Unknown' }}
    </v-card-title>

    <v-card-text>
      <div
        class="mb-3"
        v-html="comment.comment"
      />
    </v-card-text>

    <v-card-actions>
      <small class="text-muted">
        <v-icon
          icon="fas fa-clock"
          class="me-1"
        />
        <span v-if="isInFutureByAtLeast(comment.created_at)">{{ toLongDateWithDayAndTime(comment.created_at) }}</span>
        <span v-else>{{ fromNow(comment.created_at) }}</span>
        <span v-if="(comment.commenter && (comment.commenter.id == authUser.id)) || can('manage-tasks')">

          <task-comment-edition-dialog
            mode="edit"
            :model-id="comment.id"
            :task="task"
            @reload="emit('reload')"
          />
          <deletion-dialog
            :model-name="t('task.task_comment')"
            :delete-url="`/api/taskcomments/${comment.id}`"
            @model:deleted="emit('model:deleted')"
          />
        </span>
      </small>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useAuth, useDate, usePermissions } from '@/composables';
import { useI18n } from 'vue-i18n';
import DeletionDialog from '../crud/DeletionDialog.vue';
import UserAvatar from '../images/UserAvatar.vue';
import TaskCommentEditionDialog from './TaskCommentEditionDialog.vue';

defineProps<{
  comment?: App.Models.TaskComment;
  task: App.Models.Task;
}>();
const emit = defineEmits(['model:updated', 'model:created', 'model:deleted', 'reload']);
const { t } = useI18n();
const { can } = usePermissions();
const { authUser } = useAuth();

const { isInFutureByAtLeast, toLongDateWithDayAndTime, fromNow } = useDate();
</script>
