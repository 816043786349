<template>
  <v-card
    class="text-center fill-height d-flex flex-column"
    rounded="lg"
    data-testid="current-need-widget"
  >
    <v-card-title
      class="bg-primary-lighten-1 pt-7"
      style="min-height: 200px"
    >
      <div class="text-h4 text-capitalize text-primary-darken-1 mb-7 text-wrap">
        {{ t('shift.current_needs') }}
      </div>
      <h1>
        <v-icon
          size="large"
          icon="fas fa-chart-line"
          color="primary"
        />
      </h1>
    </v-card-title>
    <v-card-title class="text-primary-darken-1 text-h6">
      {{ t('shift.current_needs') }}
    </v-card-title>
    <v-card-text class="text-body-1">
      <p>
        {{ t('shift.current_needs_description') }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        to="/currentneeds"
        block
        size="large"
        color="primary"
        :text="t('setting.current_needs_view')"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
