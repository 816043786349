<template>
  <v-btn
    size="x-small"
    class="border-right pub_card_action flex-grow-1"
    :prepend-icon="icon"
    stacked
    variant="text"
    @click="emit('click')"
  >
    <div
      class="lsm-truncate"
      :class="{ 'mw-40': xs }"
    >
      {{ caption }}
    </div>
  </v-btn>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';

defineProps<{
  icon: string;
  caption: string;
}>();
const emit = defineEmits(['click']);
const { xs } = useDisplay();
</script>

<style scoped>
.pub_card_action:hover {
  /* filter: brightness(120%) */
  color: rgb(var(--v-theme-primary-lighten-1));
}

.mw-40 {
  max-width: 40px;
}
</style>
