import type { I18n, IntlDateTimeFormats } from 'vue-i18n';
import type { Router } from 'vue-router';
import ActivateUser from '@/components/ActivateUser.vue';
import CurrentCarts from '@/components/carts/CurrentCarts.vue';
import ChangePassword from '@/components/ChangePassword.vue';
import ContactSubmit from '@/components/contacts/ContactSubmit.vue';
import CookiePolicy from '@/components/CookiePolicy.vue';
import CourseSessionDetails from '@/components/coursesessions/CourseSessionDetails.vue';
import CurrentNeedsOverview from '@/components/CurrentNeedsOverview.vue';
import DayOverseerView from '@/components/dayoverseers/DayOverseerView.vue';
import ShowDocument from '@/components/documents/ShowDocument.vue';
import ExperiencesViewer from '@/components/experiences/ExperiencesViewer.vue';
import ShowExperience from '@/components/experiences/ShowExperience.vue';
import FaqShow from '@/components/faqs/FaqShow.vue';
import FaqViewer from '@/components/faqs/FaqViewer.vue';
import LocationDetails from '@/components/locations/LocationDetails.vue';
import LocationList from '@/components/locations/LocationList.vue';
import CurrentNews from '@/components/news/NewsCurrentArticles.vue';
import PageNotFound from '@/components/PageNotFound.vue';
import PermissionDenied from '@/components/PermissionDenied.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import RequiredUserDetails from '@/components/RequiredUserDetails.vue';
import MyCalendar from '@/components/shifts/MyCalendar.vue';
import MyShifts from '@/components/shifts/MyShifts.vue';
import ShowShift from '@/components/shifts/ShowShift.vue';
import StatisticPage from '@/components/StatisticPage.vue';
import ShowStore from '@/components/stores/ShowStore.vue';
import TaskDetails from '@/components/tasks/TaskDetails.vue';
import TaskManager from '@/components/tasks/TaskManager.vue';
import MyTeams from '@/components/teams/MyTeams.vue';
import TermsPolicy from '@/components/TermsPolicy.vue';
import MyDetails from '@/components/users/MyDetails.vue';
import MySpouseDetails from '@/components/users/MySpouseDetails.vue';
import ShowVideo from '@/components/videos/ShowVideo.vue';
import { useAuth, useSettings } from '@/composables';
import adminRoutes from '@/router/adminRoutes';
import helpRoutes from '@/router/helpRoutes';
import { loginGuard } from '@/router/loginGuard';
import { permissionGuard } from '@/router/permissionGuard';
import unauthenticatedRoutes from '@/router/unauthenticatedRoutes';
import { createRouter, createWebHistory } from 'vue-router';

const { getSetting } = useSettings();
const CITY_NAME = getSetting('city_name');
const DEFAULT_TITLE = `${getSetting('smpw_acronym')} | ${CITY_NAME}`;
const { authUser } = useAuth();
export const routes = [
  ...unauthenticatedRoutes,
  ...helpRoutes,
  ...adminRoutes,
  {
    path: '/',
    name: 'global.home',
    component: async () => import('@/components/widgets/WidgetManager.vue'),
  },
  {
    path: '/font',
    name: 'Fonts',
    component: async () => import('@/pages/TypographyPage.vue'),
  },
  {
    path: '/dayoverseers',
    name: 'dayoverseer.title',
    component: DayOverseerView,
    meta: {
      permission: [
        'access-dayoverseers-page',
        'manage-dayoverseers',
      ],
    },
  },
  {
    path: '/userdetailsrequest',
    name: 'user.details',
    component: RequiredUserDetails,
  },

  {
    path: '/news/current',
    name: 'news.title',
    component: CurrentNews,
  },
  {
    path: '/experiences/:id',
    name: 'experience.view',
    component: ShowExperience,
    props: true,
    meta: { permission: 'access-experiences-page' },
  },
  {
    path: '/contacts/submit',
    name: 'contact.title',
    component: ContactSubmit,
    meta: { permission: 'submit-contact-message' },
  },
  {
    path: '/documents/:id',
    name: 'document.view',
    component: ShowDocument,
    props: true,
  },
  {
    path: '/videos/:id',
    name: 'video.view',
    component: ShowVideo,
    props: true,
  },
  {
    path: '/myshifts',
    name: 'shift.mine',
    component: MyShifts,
  },
  {
    path: '/mycalendar',
    name: 'calendar.mine',
    component: MyCalendar,
    meta: { permission: 'submit-availability' },
  },
  {
    path: '/statistics',
    name: 'global.statistics',
    component: StatisticPage,
    meta: { permission: 'access-statistics-page' },
  },
  {
    path: '/shifts/details/:id',
    name: 'shift.view_details',
    component: ShowShift,
    props: true,
  },
  {
    path: '/stores/:id',
    name: 'store.view',
    component: ShowStore,
    props: true,
    meta: {
      permission: [
        'manage-stores',
        'access-locations-page',
      ],
    },
  },
  {
    path: '/training/:id',
    name: 'course.view_training_session',
    component: CourseSessionDetails,
  },

  {
    path: '/tasks/:id',
    name: 'task.view',
    component: TaskDetails,
    props: true,
    meta: {
      permission: [
        'handle-tasks',
        'manage-tasks',
      ],
    },
  },
  {
    path: '/tasks',
    name: 'task.title',
    component: TaskManager,
    meta: {
      permission: [
        'handle-tasks',
        'manage-tasks',
      ],
    },
  },
  {
    path: '/locations/:locationId',
    name: 'location.view',
    component: LocationDetails,
    props: true,
    meta: {
      permission: [
        'manage-locations',
        'access-locations-page',
      ],
    },
  },
  {
    path: '/locations',
    name: 'location.title',
    component: LocationList,
    meta: {
      permission: [
        'manage-locations',
        'access-locations-page',
      ],
    },
  },
  {
    path: '/experiences',
    name: 'experience.title',
    component: ExperiencesViewer,
    meta: { permission: 'access-experiences-page' },
  },
  {
    path: '/policies/termsandconditions',
    name: 'policies.terms',
    component: TermsPolicy,
  },
  {
    path: '/policies/privacy',
    name: 'policies.privacy',
    component: PrivacyPolicy,
  },
  {
    path: '/policies/cookies',
    name: 'policies.cookie',
    component: CookiePolicy,
  },
  {
    path: '/myteams',
    name: 'team.mine',
    component: MyTeams,
  },
  {
    path: '/faqs',
    name: 'faq.title',
    component: FaqViewer,
    meta: { permission: 'access-faqs-page' },
  },
  {
    path: '/faqs/:id',
    name: 'faq.view',
    component: FaqShow,
    props: true,
    meta: { permission: 'access-faqs-page' },
  },

  {
    path: '/carts',
    name: 'cart.title',
    component: CurrentCarts,
    meta: { permission: 'access-cart-page' },
  },
  {
    path: '/currentneeds',
    name: 'z.current_needs',
    component: CurrentNeedsOverview,
  },

  {
    path: '/mydetails',
    name: 'global.my_details',
    component: MyDetails,
  },
  {
    path: '/mydetails/edit',
    name: 'global.my_details_edit',
    component: async () => import('@/components/users/UserEditionFormWrapper.vue'),
    props: () => ({ modelId: authUser.id, mode: 'edit' }),
  },
  {
    path: '/myspousedetails',
    name: 'user.spouse_details',
    component: MySpouseDetails,
  },
  {
    path: '/changepassword',
    name: 'password.change',
    component: ChangePassword,
  },
  {
    path: '/activateuser/:id',
    name: 'user.activation.activate_publisher',
    component: ActivateUser,
  },
  {
    path: '/unauthorized',
    name: 'permission.denied',
    component: PermissionDenied,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'global.page_not_found',
    component: PageNotFound,
  },
];

export function createAppRouter(i18n: I18n<Record<string, unknown>, IntlDateTimeFormats, Record<string, unknown>, string, false>): Router {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior() {
      return { top: 0 };
    },
  });

  router.beforeEach(loginGuard);
  router.beforeEach(permissionGuard);
  router.beforeEach(async (to, from, next) => {
    if (typeof to.name === 'string' && i18n.global.te(to.name)) {
      document.title = `${DEFAULT_TITLE} | ${i18n.global.t(to.name)}`;
    }
    else {
      document.title = DEFAULT_TITLE;
    }
    next();
  });

  return router;
}
