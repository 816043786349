<template>
  <div>
    <user-avatar :user="user" />
    <div
      v-if="can('manage-users') && showOptions"
      class="m-2"
    >
      <p v-if="status === 'approved'">
        {{ t('global.picture_approved') }}
      </p>
      <p v-if="status === 'rejected'">
        {{ t('global.picture_rejected') }}
      </p>
      <p v-if="status === 'required'">
        {{ t('global.picture_required') }}
      </p>
      <template v-if="status !== 'required'">
        <v-btn
          v-if="status !== 'approved'"
          class="mx-1"
          color="success"
          :text="t('actions.approve') "
          @click="approveImage(true)"
        />
        <v-btn
          v-if="status !== 'rejected'"
          class="mx-1"
          color="error"
          :text="t('actions.reject') "
          @click="approveImage(false)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions, useSettings } from '@/composables/index.ts';
import axios from 'axios';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import UserAvatar from '../images/UserAvatar.vue';

type ApprovalStatus = 'approved' | 'rejected' | 'required';

const props = defineProps<{
  user: App.Models.User;
}>();
const emit = defineEmits(['status-change', 'status-error']);
const { getSetting } = useSettings();
const { can } = usePermissions();
const { t } = useI18n();

const updateRoute = computed(() => `/api/users/${props.user.id}/profileimage`);
const showOptions = computed(() => {
  return !getSetting('profile_image_required') ? false : !!props.user.profile_image;
});
const status = computed(() => props.user?.profile_image?.status as ApprovalStatus | undefined);

async function approveImage(approved: boolean) {
  // Why are we returning a translation in the event payload? This should be handled by the subscriber.
  try {
    const response = await axios.post(updateRoute.value, { approved });
    emit(response.data.result === 'success' ? 'status-change' : 'status-error', approved);
  }
  catch {
    emit('status-error', approved);
  }
}
</script>
