<template>
  <div>
    <div v-if="authUser">
      <breadcrumb-header :items="breadcrumbs" />
    </div>
    <div v-else>
      <global-header :title="t('policies.terms')" />
    </div>
    <v-container>
      <rich-text-content
        :data="getSetting('terms_and_conditions')"
      />
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useAuth, useSettings } from '@/composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import GlobalHeader from './GlobalHeader.vue';
import RichTextContent from './RichTextContent.vue';

const { t } = useI18n();

const { getSetting } = useSettings();
const { authUser } = useAuth();

const breadcrumbs = computed(() => [
  {
    title: t('policies.terms'),
  },
]);
</script>
