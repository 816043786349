<template>
  <div class="main-container mb-8 w-100">
    <div class="editor-container_classic-editor">
      <div class="editor-container__editor rich-text-editor">
        <ckeditor
          v-if="editor && config"
          v-model="text"
          class="bg-red"
          :editor="editor"
          :config="config"
        />
      </div>
      <div
        v-if="error"
        class="text-label text-error"
      >
        <div
          v-for="message in errorMessages"
          :key="message"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { EditorConfig } from 'ckeditor5';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';

import {
  Alignment,
  Autoformat,
  AutoImage,
  Autosave,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  ClassicEditor,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  Paragraph,
  PasteFromOffice,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
} from 'ckeditor5';
// @ts-ignore
import coreTranslationsEn from 'ckeditor5/translations/en.js';
// @ts-ignore
import coreTranslationsEs from 'ckeditor5/translations/es.js';
// @ts-ignore
import coreTranslationsFr from 'ckeditor5/translations/fr.js';
// @ts-ignore
import coreTranslationsIt from 'ckeditor5/translations/it.js';
// @ts-ignore
import coreTranslationsPt from 'ckeditor5/translations/pt.js';

import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

withDefaults(defineProps<{
  error?: boolean;
  errorMessages?: string[];
  heading?: string;
  label?: string;
  hint?: string;
  singleLine?: boolean;
}>(), { singleLine: false });

const { t, locale } = useI18n();

const text = defineModel<string>();

const LICENSE_KEY = 'GPL';

const isLayoutReady = ref(false);

const editor = ClassicEditor;

const config = computed<EditorConfig | undefined>(() => {
  if (!isLayoutReady.value) {
    return null;
  }

  return {
    language: locale.value,
    translations: [
      coreTranslationsPt,
      coreTranslationsEn,
      coreTranslationsEs,
      coreTranslationsIt,
      coreTranslationsFr,
    ],
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'heading',
        '|',
        'fontSize',
        // 'fontFamily',
        'fontColor',
        // 'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        // 'underline',
        // 'code',
        '|',
        'horizontalLine',
        'link',
        // 'insertImage',
        // 'mediaEmbed',
        'insertTable',
        // 'blockQuote',
        // 'codeBlock',
        '|',
        'alignment',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
      ],
    },
    plugins: [
      Alignment,
      Autoformat,
      AutoImage,
      Autosave,
      BlockQuote,
      Bold,
      Base64UploadAdapter,
      Essentials,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      HorizontalLine,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      Paragraph,
      PasteFromOffice,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Underline,
    ],
    // fontFamily: {
    //   supportAllValues: true,
    // },
    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: t('editor.paragraph'),
          view: {
            name: 'p',
            classes: 'text-body-1',
          },
          class: 'ck-heading_paragraph',
        },
        {
          model: 'heading4',
          view: {
            name: 'h4',
            classes: 'text-h4',
          },
          title: t('editor.heading_h4'),
          class: 'ck-heading_heading4',
        },
        {
          model: 'heading5',
          view: {
            name: 'h5',
            classes: 'text-h5',
          },
          title: t('editor.heading_h5'),
          class: 'ck-heading_heading5',
        },
        {
          model: 'heading6',
          view: {
            name: 'h6',
            classes: 'text-h6',
          },
          title: t('editor.heading_h6'),
          class: 'ck-heading_heading6',
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'resizeImage',
      ],
    },
    licenseKey: LICENSE_KEY,
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: t('editor.downloadable'),
          attributes: {
            download: 'file',
          },
        },
        openInNewTab: {
          mode: 'manual',
          label: t('editor.open_in_new_tab'),
          defaultValue: false,
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
      },
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
    },
  };
});

onMounted(() => {
  isLayoutReady.value = true;
});
</script>
