<template>
  <v-alert
    v-if="getSetting('cookie_consent_enabled')"
    type="info"
  >
    <rich-text-content
      :data="getSetting('cookie_consent_message')"
    />
  </v-alert>
</template>

<script setup lang="ts">
import { useSettings } from '@/composables';
import RichTextContent from './RichTextContent.vue';

const { getSetting } = useSettings();
</script>
