<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/after-shifts/contact-us"
    />
    <v-container style="max-width: 1000px;">
      <div
        v-if="!submitted"
      >
        <rich-text-content
          class="mb-4"
          :data="getSetting('contact_explanation_text')"
        />

        <model-select-dropdown
          v-model="contactMessageOptionId"
          :label="t('global.category')"
          :autocomplete="false"
          index-route="/api/contactmessageoptions"
          label-field-name="category"
          sort-field-name="id"
          load-all
          :per-page="300"
          :error="!!validationErrors.contactmessageoption_id"
          :error-messages="validationErrors.contactmessageoption_id"
        />

        <v-textarea
          v-model="contactMessage"
          name="contact_message"
          :label="t('contact.your_message')"
          rows="15"
          :error="!!validationErrors.contact_message"
          :error-messages="validationErrors.contact_message"
        />

        <v-btn
          color="secondary"
          size="large"
          prepend-icon="fas fa-envelope"
          :text="t('contact.submit_message')"
          @click="submitContactForm"
        />
      </div>
      <div
        v-else
      >
        <v-alert
          class="text-font"
          :title="t('contact.thanks')"
        >
          <span v-html="getSetting('contact_thank_you_text')" />
        </v-alert>
      </div>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import type { AxiosError } from 'axios';
import ModelSelectDropdown from '@/components/crud/ModelSelectDropdown.vue';
import { useSettings } from '@/composables/index.ts';
import { useErrorStore } from '@/stores/errors.ts';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import RichTextContent from '../RichTextContent.vue';

const { t } = useI18n();
const { showSnackMessage } = useErrorStore();
const breadcrumbs = computed(() => [{ title: t('actions.contact_us') }]);
const { getSetting } = useSettings();

const errorStore = useErrorStore();
const { validationErrors } = storeToRefs(errorStore);
const contactMessageOptionId = ref(null);
const contactMessage = ref('');
const submitted = ref(false);

async function submitContactForm() {
  try {
    await axios.post('/api/contacts/notify', {
      contact_message: contactMessage.value,
      contactmessageoption_id: contactMessageOptionId.value,
    });
    submitted.value = true;
  }
  catch (error) {
    const errors = error as Error | AxiosError;
    let message = t('contact.error_sending_message');
    if (axios.isAxiosError(errors)) {
      if (errors.response?.status === 422) {
        validationErrors.value = errors.response.data.errors;
      }
      message = errors?.response?.data?.message;
    }
    showSnackMessage(message);
  }
}
</script>
