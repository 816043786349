export function usePermissions() {
  const can = (permissionName: string): boolean => {
    return window.permissions?.includes(permissionName) || false;
  };
  const canAny = (permissionNames: string[]): boolean => {
    return (
      window.permissions != null && window.permissions.length > 0
      && permissionNames.some((permission) => window.permissions.includes(permission))
    );
  };
  const spouseCan = (permissionName) => window.spouse_permissions?.includes(permissionName);
  const spouseCanAny = (permissionNames: string[]): boolean => {
    return (
      window.spouse_permissions != null && window.spouse_permissions.length > 0
      && permissionNames.some((permission) => window.spouse_permissions.includes(permission))
    );
  };
  return {
    can,
    canAny,
    spouseCan,
    spouseCanAny,
  };
}
