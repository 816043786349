<template>
  <model-edition-form
    ref="form"
    v-model="task"
    :model-name="t('task.title')"
    base-url="/api/tasks"
    :model-id="props.modelId"
    :mode="props.mode"
    load-writable
    @close="emit('close')"
    @model:updated="emit('model:updated')"
    @model:created="emit('model:created')"
  >
    <div
      v-if="task"
    >
      <v-text-field
        v-if="canWriteField('title')"
        v-model="task.title"
        name="title"
        :label="t('global.title')"
        autofocus
        :error="!!validationErrors.title"
        :error-messages="validationErrors.title"
        :hint="t('task.title_hint')"
        persistent-hint
      />

      <user-select
        v-model="task.assignee_id"
        persistent-hint
        :label="t('task.assignee')"
        label-field-name="name"
        :error="!!validationErrors.assignee_id"
        :error-messages="validationErrors.assignee_id"
        required-permission="handle-tasks"
        active-only
        :return-object="false"
        :basic="false"
      />

      <v-select
        v-if="mode != 'create' && canWriteField('status')"
        v-model="task.status"
        :label="t('global.status')"
        :items="taskStates"
        :error="!!validationErrors.status"
        :error-messages="validationErrors.status"
      />
      <model-select-dropdown
        v-model="task.task_category_id"
        :label="t('global.category')"
        index-route="/api/taskcategories"
        label-field-name="name"
        load-all
        :per-page="300"
        hint=" Select the category for this task."
      />

      <model-select-dropdown
        v-model="task.store_id"
        :label="t('store.title')"
        index-route="/api/stores"
        :filters="storeFilter"
        label-field-name="name"
        sort-field-name="name"
        load-all
        :per-page="300"
        :hint="t('task.store_hint')"
      />
      <date-picker
        v-if="canWriteField('due_date')"
        v-model="task.due_date"
        :label="t('date.due')"
        :error="!!validationErrors.due_date"
        :error-messages="validationErrors.due_date"
        :hint="t('task.due_date_hint')"
        persistent-hint
      />

      <rich-text-editor
        v-model="task.description"
        :heading="t('global.description')"
        :hint="t('task.description_hint')"
      />
    </div>
  </model-edition-form>
</template>

<script setup lang="ts">
import ModelEditionForm from '@/components/ModelEditionForm.vue';
import { useDataTableUtilities } from '@/composables/index.ts';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ModelSelectDropdown from '../crud/ModelSelectDropdown.vue';
import RichTextEditor from '../RichTextEditor.vue';
import UserSelect from '../users/UserSelect.vue';

const props = defineProps<{
  modelId?: number;
  mode: 'edit' | 'create' | 'show';
}>();
const emit = defineEmits(['model:updated', 'model:created', 'model:loaded', 'error:handled', 'close']);
const { t } = useI18n();
const { canWriteField, loadWritableAttributes } = useDataTableUtilities();
const storeFilter = [{
  column: 'active',
  operator: '=',
  value: true,
}];
const defaultItem = {
  id: -1,
  title: '',
  description: '',
  task_category_id: undefined,
  store_id: undefined,
  due_date: undefined,
  comments: [],
  status: 'new',
};

const task = ref<Partial<App.Models.Task>>(defaultItem);

const taskStates = ref<any>([]);

async function loadTaskStates() {
  const response = await axios.get('/api/tasks/states');
  taskStates.value = response.data.data.status.map((status: string) => ({
    value: status,
    title: t(`states.${status}`),
  }));
}

loadTaskStates();
loadWritableAttributes('/api/tasks', props.modelId);

const form = ref();
const errorStore = useErrorStore();
const { validationErrors } = storeToRefs(errorStore);
watch(task, () => {
  form.value.validate();
}, { deep: true });
</script>
