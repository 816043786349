<template>
  <v-row>
    <v-col>
      <v-file-input
        :label="caption"
        accept=".jpg, .png"
        :multiple="multipleImages"
        :error="error"
        :error-messages="errorMessages"
        hide-details
        @change="onFileChange"
      />
      <v-alert
        v-show="errorMessageCountDown"
        type="error"
        class="mt-2"
        closable
        :text="errorMessage"
      />
      <v-alert
        v-show="warningMessageCountDown"
        type="warning"
        class="mt-2"
        :text="warningMessage"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = withDefaults(defineProps<{
  onUploadImage: (images: { id: null; data: string; title: string }[]) => void;
  caption?: string;
  multipleImages: boolean;
  onUploadImageGuard: (image: HTMLImageElement) => { hasError: boolean; errorMessage?: string; hasWarning: boolean; warningMessage?: string };
  errorMessages?: string | string[];
  error?: boolean;
}>(), {
  caption: () => useI18n().t('global.browse'),
  multipleImages: () => false,
});

const base64Images = ref<any>([]);
const errorMessage = ref('');
const warningMessage = ref('');
const errorMessageCountDown = ref(null);
const warningMessageCountDown = ref(null);

function calculateImageSize(base64String) {
  let padding = 0;
  let inBytes = 0;
  let base64StringLength = 0;
  if (base64String.endsWith('==')) {
    padding = 2;
  }
  else if (base64String.endsWith('=')) {
    padding = 1;
  }

  base64StringLength = base64String.length;
  inBytes = (base64StringLength / 4) * 3 - padding;
  return inBytes;
}

function createImage(file, totalFiles) {
  base64Images.value = [];
  const reader = new FileReader();
  reader.onload = (event) => {
    if (!event.target) {
      return;
    }
    if (typeof event.target.result !== 'string') {
      return;
    }
    const isFileImage = event.target?.result?.substring(0, 10).indexOf('data:image') === 0;
    if (isFileImage) {
      // Only accept image files
      const img = new Image();
      img.src = event.target.result;
      /* @ts-ignore */
      img.byteSize = calculateImageSize(img.src);
      img.onload = () => {
        if (props.onUploadImageGuard != null) {
          const outcome = props.onUploadImageGuard(img);
          if (outcome.hasError) {
            errorMessage.value = outcome.errorMessage ?? 'An error occurred';
            return;
          }
          if (outcome.hasWarning) {
            warningMessage.value = outcome.warningMessage ?? 'Warning: Check your file';
          }
        }
        base64Images.value.push({
          id: null,
          data: event.target?.result,
          title: '',
        });
        if (base64Images.value.length === totalFiles) {
          props.onUploadImage(base64Images.value);
        }
      };
    }
  };
  reader.readAsDataURL(file);
}

function onFileChange(e) {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) {
    return;
  }
  Array.from(files).forEach((file) => {
    createImage(file, files.length);
  });
}
</script>
