<template>
  <v-avatar
    :style="style"
    :size="size"
    :class="user.gender == 'b' ? 'user_card_pic_border_b' : 'user_card_pic_border_s'"
    class="user_card_pic_border my-1 mx-1"
    @click="displayZoom"
  >
    <v-img
      v-if="tooltip"
      v-tooltip="tooltip"
      :src="user.profile_image_url"
    />
    <v-img
      v-else
      :src="user.profile_image_url"
    />
    <v-dialog v-model="dialogShown">
      <global-card
        closable
        @close="closeDialog"
      >
        <v-avatar
          :style="style"
          size="100%"
          :class="user.gender == 'b' ? 'user_card_pic_border_b' : 'user_card_pic_border_s'"
          class="user_card_pic_border my-1 mx-1 align-center justify-center"
        >
          <v-img
            v-if="tooltip"
            v-tooltip="tooltip"
            :src="user.profile_image_url"
          />
          <v-img
            v-else
            :src="user.profile_image_url"
          />
        </v-avatar>
        <template #actions>
          <v-btn
            color="dark-grey"
            :text="$t('actions.close')"
            @click="closeDialog"
          />
        </template>
      </global-card>
    </v-dialog>
  </v-avatar>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const props = withDefaults(defineProps < {
  user: Partial<App.Models.User | App.ApiResources.PublisherWithShiftDetailsResource>;
  size?: string;
  tooltip?: string;
  new?: boolean;
  trainer?: boolean;
  notAcknowleged?: boolean;
  captain?: boolean;
  zoomEnabled?: boolean;
  guest?: boolean;
}>(), {
  size: () => '150',
  new: () => false,
  guest: () => false,
  captain: () => false,
  trainer: () => false,
  notAcknowleged: () => false,
  zoomEnabled: () => false,
});

const dialogShown = ref(false);
function displayZoom() {
  if (!props.zoomEnabled) {
    return;
  }
  dialogShown.value = true;
}
function closeDialog() {
  dialogShown.value = false;
}
const style = computed(() => {
  if (props.guest) {
    return 'box-shadow: red 0px 0px 20px 5px !important;';
  }
  if (props.new) {
    return 'box-shadow: #FBC02D 0px 0px 20px 5px !important;';
  }
  if (props.trainer) {
    return 'box-shadow: green 0px 0px 20px 5px !important;';
  }
  if (props.captain) {
    return 'box-shadow: #4d89db 0px 0px 20px 5px !important;';
  }
  return '';
});
</script>

<style scoped>
.user_card_pic_border {
    border-width: 3px;
    border-style: solid;
    border-color: white;
}
.user_card_pic_border:hover {
    filter: brightness(110%);
}
.user_card_pic_border_b {
  outline: 3px solid #4d89db;
}
.user_card_pic_border_s {
  outline: 3px solid #F240AE;
}
</style>
