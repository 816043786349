<template>
  <div>
    <breadcrumb-header :items="breadcrumbs" />
    <v-alert
      class="mb-6 mt-6"
      icon="fas fa-warning"
      :text="t('required_details.message_1')"
    />
    <v-row>
      <v-col
        v-if="!authUser.year_of_birth"
        cols="12"
        md="6"
      >
        <label
          for="user_year_of_birth"
          class="text-subtitle-1 text-medium-emphasis"
        >
          {{ t('user.year_of_birth') }}
        </label>
        <v-text-field
          v-model="yearOfBirth"
          placeholder="e.g. 1980"
          prepend-inner-icon="fas fa-calendar"
          name="user_year_of_birth"
          type="number"
          min="1900"
          :error-messages="errors.user_year_of_birth"
          :max="thisYear()"
        />
      </v-col>
      <v-col
        v-if="!authUser.date_of_baptism"
        cols="12"
        md="6"
      >
        <label
          for="user_date_of_baptism"
          class="text-subtitle-1 text-medium-emphasis"
        >
          {{ t('user.date_of_baptism') }}
        </label>
        <date-picker
          v-model="dateOfBaptism"
          :clearable="false"
          name="user_date_of_baptism"
          :error="!!errors.user_date_of_baptism"
          :error-messages="errors.user_date_of_baptism"
        />
      </v-col>
      <v-col
        v-if="!authUser.emergency_contact_name"
        cols="12"
        md="6"
      >
        <label
          for="emergency_contact_name"
          class="text-subtitle-1 text-medium-emphasis"
        >
          {{ t('user.emergency_contact_name') }}
        </label>
        <v-text-field
          v-model="emergencyContactName"
          name="user_emergency_contact_name"
          placeholder="Contact Name"
          prepend-inner-icon="fas fa-user"
          :error-messages="errors.user_emergency_contact_name"
        />
      </v-col>
      <v-col
        v-if="!authUser.emergency_contact_phone"
        cols="12"
        md="6"
      >
        <label
          for="emergency_contact_phone"
          class="text-subtitle-1 text-medium-emphasis"
        >
          {{ t('user.emergency_contact_phone') }}
        </label>
        <v-text-field
          v-model="emergencyContactPhone"
          name="user_emergency_contact_phone"
          placeholder="e.g. 0712345678"
          prepend-inner-icon="fas fa-phone"
          :error-messages="errors.user_emergency_contact_phone"
        />
      </v-col>
    </v-row>
    <v-btn
      color="primary"
      size="large"
      prepend-icon="fas fa-check"
      class="mt-6"
      :text="t('required_details.update_details')"
      @click="submit"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuth, useDate } from '@/composables';

import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VTextField } from 'vuetify/lib/components/index.mjs';

const { t } = useI18n();

const yearOfBirth = ref<string | null>(null);
const dateOfBaptism = ref<string | null>(null);
const emergencyContactName = ref<string | null>(null);
const emergencyContactPhone = ref<string | null>(null);
const errors = ref<{ user_year_of_birth?: string; user_date_of_baptism?: string; user_emergency_contact_name?: string; user_emergency_contact_phone?: string }>({});

const { showSnackMessage } = useErrorStore();

const { thisYear } = useDate();
const { authUser } = useAuth();

const breadcrumbs = computed(() => [{ title: t('user.missing_details') }]);

function submit() {
  axios.post('/api/userdetailsrequest', {
    user_year_of_birth: yearOfBirth.value,
    user_date_of_baptism: dateOfBaptism.value,
    user_emergency_contact_name: emergencyContactName.value,
    user_emergency_contact_phone: emergencyContactPhone.value,
  }).then(() => {
    showSnackMessage(t('required_details.data_updated_successfully'), 'success');
    setTimeout(() => window.location.reload(), 3000);
  }).catch((error) => {
    showSnackMessage(t('required_details.data_update_error'), 'error');
    errors.value = error.response?.data?.errors ?? {};
  });
}
</script>
