<template>
  <v-card
    class="fill-height text-center d-flex flex-column"
    rounded="lg"
    data-testid="experience-widget"
  >
    <v-card-title
      class="bg-primary-lighten-1 pt-7 "
      style="min-height: 200px"
    >
      <div class="text-h4 text-h4-5 text-capitalize text-primary-darken-1 mb-7 text-wrap">
        {{ t('experience.todays') }}
      </div>
      <h1>
        <v-icon
          size="large"
          icon="fas fa-bullhorn"
          color="primary"
        />
      </h1>
    </v-card-title>
    <v-card-title class="text-primary-darken-1 text-h6">
      <span class="text-h6">{{ props.experience.title }}</span>
      <br>
      <span class="text-subtitle-1">{{ toLongDate(props.experience.date) }} - {{ props.experience?.location?.name }}</span>
    </v-card-title>
    <v-card-text class="text-body-1">
      <p style="white-space: pre-wrap;">
        {{ experienceText }}
        <router-link
          class="text-primary text-decoration-none"
          :to="`/experiences/${experience.id}`"
          :text="t('global.read_more')"
        />
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        to="/experiences"
        size="large"
        block
        :text="t('experience.read_more')"
        color="primary"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  experience: App.Models.Experience;
}>();

const { t } = useI18n();

const { toLongDate } = useDate();

function truncate(str: string, n: number) {
  return (str.length > n) ? `${str.substr(0, n - 1)}...` : str;
}

const experienceText = computed(() => truncate(props.experience.experience, 80));
</script>
