import { initAxios } from '@/axios';
import BreadcrumbHeader from '@/components/BreadcrumbHeader.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import CookiePolicy from '@/components/CookiePolicy.vue';
import FaqsList from '@/components/FaqsList.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import DatePicker from '@/components/globals/DatePicker.vue';
import GlobalCard from '@/components/globals/GlobalCard.vue';
import LoadingSpinner from '@/components/globals/LoadingSpinner.vue';
import RadioButtonGroup from '@/components/globals/RadioButtonGroup.vue';
import SmpwTab from '@/components/globals/SmpwTab.vue';
import SnackBar from '@/components/globals/SnackBar.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import LocationList from '@/components/locations/LocationList.vue';
import LoginBox from '@/components/LoginBox.vue';
import MainMenu from '@/components/MainMenu.vue';

import PasswordInput from '@/components/PasswordInput.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';
import VersionFooter from '@/components/VersionFooter.vue';
import WidgetManager from '@/components/widgets/WidgetManager.vue';
import { initI18n } from '@/i18n';
import { createAppRouter } from '@/router';
import { initVuetify } from '@/vuetify';
import { createPinia } from 'pinia';
import { createApp, defineAsyncComponent } from 'vue';

const DocP = defineAsyncComponent(async () => import('@/components/documentation/DocP.vue'));
const DocS = defineAsyncComponent(async () => import('@/components/documentation/DocS.vue'));
const DocSs = defineAsyncComponent(async () => import('@/components/documentation/DocSS.vue'));
const DocH = defineAsyncComponent(async () => import('@/components/documentation/DocH.vue'));
const DocLi = defineAsyncComponent(async () => import('@/components/documentation/DocLi.vue'));
const DocUl = defineAsyncComponent(async () => import('@/components/documentation/DocUl.vue'));
const DocOl = defineAsyncComponent(async () => import('@/components/documentation/DocOl.vue'));

const i18n = initI18n();
const vuetify = initVuetify(i18n);
const app = createApp({});
const pinia = createPinia();

initAxios();

app.component('LanguageSwitcher', LanguageSwitcher);
app.component('BreadcrumbHeader', BreadcrumbHeader);
app.component('PhoneNumber', PhoneNumber);
app.component('DatePicker', DatePicker);
app.component('DocOl', DocOl);
app.component('SmpwTab', SmpwTab);
app.component('DocUl', DocUl);
app.component('DocLi', DocLi);
app.component('DocH', DocH);
app.component('DocS', DocS);
app.component('DocSs', DocSs);
app.component('DocP', DocP);
app.component('RadioButtonGroup', RadioButtonGroup);
app.component('LoadingSpinner', LoadingSpinner);
app.component('GlobalCard', GlobalCard);
app.component('SnackBar', SnackBar);
app.component('CookieConsent', CookieConsent);
app.component('GlobalFooter', GlobalFooter);
app.component('MainMenu', MainMenu);
app.component('PasswordInput', PasswordInput);
app.component('LocationList', LocationList);
app.component('FaqsList', FaqsList);
app.component('WidgetManager', WidgetManager);
app.component('VersionFooter', VersionFooter);
app.component('LoginBox', LoginBox);
app.component('CookiePolicy', CookiePolicy);

app.use(i18n);
app.use(pinia);
app.use(vuetify);
app.use(createAppRouter(i18n));

app.mount('#app');
