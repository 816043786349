<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(category, catIndex) in categoryList"
        :key="catIndex"
      >
        <v-expansion-panel-title :class="rowClass(catIndex)">
          <template #actions="{ expanded }">
            <v-btn
              color="primary"
              :icon="expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
            />
          </template>
          <span class="text-h5 text-primary">{{ category }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(faq, index) in getCategoryItems(category)"
              :key="index"
            >
              <v-expansion-panel-title
                :class="rowClass(index)"
              >
                <template #actions="{ expanded }">
                  <v-btn
                    color="primary"
                    :icon="expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
                  />
                </template>
                <span class="text-h6 text-primary-darken-1">{{ faq.question }}</span>
              </v-expansion-panel-title>
              <v-expansion-panel-text :class="rowClass(index)">
                <rich-text-content
                  :data="faq.answer"
                />
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import RichTextContent from './RichTextContent.vue';

const props = defineProps<{
  items: Array<any>;
}>();
const tab = ref('');
const categoryList = ref<string[]>([]);

onMounted(() => {
  categoryList.value = [
    ...new Set(props.items.map((p) => p.categories.map((q) => q.name)).flat()),
  ];
  tab.value = categoryList.value[0];
});

function rowClass(number) {
  return (number % 2 === 0) ? 'bg-light-grey' : '';
}
function getCategoryItems(category) {
  return props.items
    .filter((p) => p.categories.map((q) => q.name).includes(category))
    .sort(
      (firstEl, secondEl) =>
        firstEl.order[category] - secondEl.order[category],
    );
}
</script>
