<template>
  <v-card @click="emit('click')">
    <v-card-title
      class="d-flex flex-row justify-space-between"
      :class="`bg-${color}`"
    >
      <div>
        <span
          class="d-flex text-sm-body-1 text-body-h6 font-weight-bold text-wrap mt-1"
          :class="color === 'primary' ? 'text-white' : 'text-primary'"
          :style="{ lineHeight: '1.5rem' }"
        >
          <b v-if="isFeatured">{{ t('experience.featured') }}:</b> {{ experience.title }}
        </span>
        <div
          class="text-sm-body-2 text-body-1 text-wrap"
          :class="color === 'primary' ? 'text-white' : 'text-font'"
        >
          {{ toLongDateWithDay(experience.date) }}
          <span v-if="experience.location"> - {{ experience.location?.name }}</span>
        </div>
      </div>
      <v-btn
        v-if="closable"
        icon="fas fa-times"
        color="white"
        variant="text"
        @click.stop="emit('close')"
      />
    </v-card-title>
    <v-card-text
      class="light-grey mt-2"
    >
      <rich-text-content
        style="white-space: pre-wrap;"
        :class="{ 'multiline-ellipsis': truncate }"
        :data="experience.experience"
      />
      <v-btn
        variant="plain"
        color="primary"
        class="float-right font-weight-bold text-sm-body-2 text-body-1"
        size="default"
        :text="t('global.read_more')"
        @click="() => readExperience(experience)"
      />
    </v-card-text>
    <v-card-actions
      v-if="closable"
    >
      <v-btn
        color="dark-grey"
        variant="elevated"
        :text="t('actions.close')"
        @click="emit('close')"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import RichTextContent from '../RichTextContent.vue';

defineProps<{
  experience: App.Models.Experience;
  truncate?: boolean;
  color?: string;
  isFeatured?: boolean;
  closable?: boolean;
}>();

const emit = defineEmits(['close', 'click']);

const { t } = useI18n();
const router = useRouter();
const { toLongDateWithDay } = useDate();

function readExperience(experienceObj: App.Models.Experience): void {
  router.push({ path: `/experiences/${experienceObj.id}` });
}
</script>
