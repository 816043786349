<template>
  <v-card
    class="text-center d-flex flex-column"
    rounded="lg"
    data-testid="course-session-confirmation-widget"
  >
    <v-card-title
      class="bg-primary-lighten-1 pt-7"
      style="min-height: 200px"
    >
      <div class="text-h4 text-capitalize text-primary-darken-1 mb-7 text-wrap">
        {{ t('course.training_confirmation') }}
      </div>
      <h1>
        <v-icon
          size="large"
          color="primary"
          icon="fas fa-chalkboard-teacher"
        />
      </h1>
    </v-card-title>
    <v-card-title class="text-primary-darken-1 text-h6">
      {{ courseSession.course?.name }}
    </v-card-title>
    <v-card-text>
      <p class="text-body-1">
        {{ t('course.please_confirm_attendance', { date: toLongDateWithDay(courseSession.date) }) }}
      </p>
      <h3
        v-if="confirmed"
        class="text-h6"
      >
        <v-icon
          color="success"
          icon="fas fa-check-circle"
        /> {{ t('course.attendance_confirmation_success') }}
      </h3>
    </v-card-text>
    <v-card-actions v-if="!confirmed">
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ props: activatorProps }">
          <v-btn
            v-bind="activatorProps"
            color="primary"
            size="large"
            block
            variant="elevated"
            :text="t('course.confirm_attendance')"
          />
        </template>
        <global-card :title="t('course.confirm_attendance')">
          <v-text-field
            v-model="confirmationCode"
            :label="t('course.confirmation_code')"
            class="mb-n10"
          />
          <template #actions>
            <v-btn
              :text="t('actions.close')"
              color="dark-grey"
              @click="close"
            />
            <v-btn
              :text="t('course.confirm_button')"
              color="primary"
              @click="confirmAttendance"
            />
          </template>
        </global-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { useAuth, useDate } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  courseSession: App.Models.CourseSession;
}>();

const { t } = useI18n();

const { authUser } = useAuth();
const { toLongDateWithDay } = useDate();
const { handleError } = useErrorStore();

const dialog = ref<boolean>(false);
const confirmed = ref<boolean>(false);
const confirmationCode = ref<string>('');

function close() {
  dialog.value = false;
}

async function confirmAttendance() {
  const url = `/api/coursesessions/${props.courseSession.id}/confirm`;
  try {
    await axios.put(url, { user_id: authUser.id, confirmation_code: confirmationCode.value });
    confirmed.value = true;
    close();
  }
  catch (error) {
    handleError(error, t('course.attendance_confirmation_error'));
  }
}
</script>
